<template>
  <li class="flex justify-between mb-2 text-gray-500">
    <span class="w-5/12">
      {{ name }}
    </span>
    <span
      class="w-full overflow-hidden overflow-ellipsis break-words text-black"
    >
      <slot></slot>
    </span>
  </li>
</template>

<script>
export default {
  props: { name: String },
};
</script>

<style></style>

<template>
  <app-layout>
    <v-container class="mb-6">
      <page-header>
        <page-title> Cerere oferta </page-title>
      </page-header>
      <div class="flex flex-col md:flex-row border rounded">
        <section class="w-auto md:w-2/5 bg-gray-100 p-6">
          <h2 class="text-xl font-medium uppercase mb-6">
            Selectati criteriile de cautare
          </h2>
          <add-rfq-item-form @create:item="addItem($event)" />
        </section>
        <section class="flex-1 bg-gray-100 p-6">
          <h2 class="text-xl font-medium uppercase mb-6">
            Detalii cerere ({{ form.items.length }} Active)
          </h2>
          <div class="mb-6" v-text="form.message"></div>

          <form
            action=""
            method="POST"
            @keydown="form.errors.clear($event.target.name)"
            @submit.prevent="onSubmit"
          >
            <div class="max-h-96 overflow-y-auto">
              <article
                v-for="(item, index) in form.items"
                :key="item.position"
                class="py-3 px-6 mb-6 bg-white shadow-md rounded"
              >
                <header
                  class="
                    border-b-2
                    mb-4
                    pb-2
                    flex
                    justify-between
                    items-baseline
                  "
                >
                  <h3 class="text-md font-medium uppercase">
                    Piesa nr {{ item.position }}
                  </h3>
                  <button
                    class="text-xs text-gray-500"
                    @click.prevent="removeItem(item.position)"
                  >
                    Sterge
                  </button>
                </header>

                <ul class="flex text-xs space-x-6">
                  <div class="flex-1">
                    <rfq-create-list-item-detail name="Marca">
                      <select-make v-model="item.make_id" class="w-full" />
                    </rfq-create-list-item-detail>
                    <rfq-create-list-item-detail
                      name="Piesa"
                      :class="
                        form.errors.has(`items.${index}.name`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      <v-input class="w-full" v-model="item.name"> </v-input>
                    </rfq-create-list-item-detail>
                  </div>
                  <div class="flex-1">

                    <rfq-create-list-item-detail
                      name="Model"
                      :class="
                        form.errors.has(`items.${index}.car_id`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      <select-car
                        :makeId="item.make_id"
                        v-model="item.car_id"
                        class="w-full"
                      />
                    </rfq-create-list-item-detail>
                    <rfq-create-list-item-detail
                      name="Cantitate"
                      :class="
                        form.errors.has(`items.${index}.quantity`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      <v-input
                        class="w-full"
                        type="number"
                        v-model="item.quantity"
                      />
                    </rfq-create-list-item-detail>
                  </div>
                </ul>

                <section class="text-xs mt-3">
                  <!-- <h4 class="font-bold uppercase">Aditionale</h4> -->
                  <ul class="flex flex-row space-x-6 mt-1">
                    <li
                      class="flex items-center"
                      :class="
                        form.errors.has(`items.${index}.pictures`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      Poze: <v-checkbox v-model="item.pictures" class="ml-3" />
                    </li>
                    <li
                      class="flex items-center"
                      :class="
                        form.errors.has(`items.${index}.warranty`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      Garantie:
                      <v-checkbox v-model="item.warranty" class="ml-3" />
                    </li>
                    <li
                      class="flex items-center"
                      :class="
                        form.errors.has(`items.${index}.return`)
                          ? 'text-red-500'
                          : ''
                      "
                    >
                      Posibilitate de retur:
                      <v-checkbox v-model="item.return" class="ml-3" />
                    </li>
                  </ul>
                </section>

                <footer class="text-xs mt-5">
                  <h4
                    class="font-bold uppercase"
                    :class="
                      form.errors.has(`items.${index}.notes`)
                        ? 'text-red-500'
                        : ''
                    "
                  >
                    Note
                  </h4>

                  <v-textarea v-model="item.notes" class="mt-1" />
                </footer>
              </article>
            </div>

            <!-- <v-label for="email">Email </v-label>
            <v-input type="text" id="email" name="email" v-model="form.email" />
            <v-error
              :error="form.errors.has('email') ? form.errors.get('email') : ''"
            /> -->

            <v-button-primary
              class="block w-full mt-5"
              :disabled="!form.items.length || form.loading"
            >
              <v-button-icon v-if="form.loading">
                <loader :radius="5" />
              </v-button-icon>
              {{ submitButtonText }}
            </v-button-primary>
          </form>
        </section>
      </div>
    </v-container>
  </app-layout>
</template>

<script>
import VContainer from "../components/VContainer.vue";
import PageHeader from "../components/PageHeader.vue";
import AppLayout from "../layout/AppLayout.vue";
// import VLabel from "../components/ui/VLabel.vue";
import VInput from "../components/ui/VInput.vue";
import Form from "../core/Form";
// import VError from "../components/ui/VError.vue";
import RfqCreateListItemDetail from "../components/RfqCreateListItemDetail.vue";
import SelectMake from "../components/SelectMake.vue";
import VCheckbox from "../components/VCheckbox.vue";
import VTextarea from "../components/VTextarea.vue";
import PageTitle from "../components/PageTitle.vue";
import AddRfqItemForm from "../components/forms/AddRfqItemForm.vue";
import { reactive, ref, computed } from "@vue/reactivity";
import { useRouter } from "vue-router";
import Loader from "../components/Loader.vue";
import VButtonPrimary from "../components/VButtonPrimary.vue";
import VButtonIcon from "../components/VButtonIcon.vue";
import rfqsApi from "../api/resources/rfqsApi";
import SelectCar from "../components/SelectCar.vue";
import authService from "../services/authService";

export default {
  components: {
    VContainer,
    PageHeader,
    AppLayout,
    // VLabel,
    VInput,
    // VError,
    RfqCreateListItemDetail,
    SelectMake,
    VCheckbox,
    VTextarea,
    PageTitle,
    AddRfqItemForm,
    Loader,
    VButtonPrimary,
    VButtonIcon,
    SelectCar,
  },

  setup() {
    const router = useRouter();
    const position = ref(1);
    const isAuthenticated = ref(authService.check());
    const form = reactive(
      new Form({
        // email: "",
        pictures: false,
        warranty: false,
        return: false,
        items: [],
      })
    );
    const submitButtonText = computed(() => {
      return isAuthenticated.value
        ? "Trimite cerere"
        : "Logheaza-te in contul tau";
    });

    let rfq = localStorage.getItem("rfq");

    if (rfq !== null && rfq !== "undefined") {
      rfq = JSON.parse(rfq);
      form.items = rfq;
      localStorage.removeItem("rfq");
    }

    let homeItem = localStorage.getItem("part");

    if (homeItem !== null && homeItem !== "undefined") {
      homeItem = JSON.parse(homeItem);
      homeItem.position = position.value++;
      form.items.push(homeItem);
      localStorage.removeItem("part");
    }

    const addItem = (item) => {
      item.position = position.value++;
      form.items.push(item);
    };

    const removeItem = (position) => {
      form.items = form.items.filter((item) => item.position !== position);
    };

    const onSubmit = () => {
      if (isAuthenticated.value) {
        form
          .submitService(rfqsApi, "store")
          .then((response) => {
            const id = response.data.id;
            router.push({
              name: "rfqs.confirmation",
              query: { id: id },
            });
          })
          .catch((e) => console.log(e));
      } else {
        localStorage.setItem("rfq", JSON.stringify(form.items));
        router.push({
          name: "login",
          query: { redirect: "cerere-oferta" },
        });
      }
    };

    return {
      form,
      addItem,
      removeItem,
      onSubmit,
      submitButtonText,
    };
  },
};
</script>

<style></style>
